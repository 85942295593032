.move-left:after,
.move-right:after,
.move-up:after,
.move-down:after,
.move-down-left:after,
.move-down-right:after,
.move-up-left:after,
.move-up-right:after {
  border: 2px solid rgba(0,0,0,0.3) !important;
}
.move-left {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translateX(-15px);
}

.move-right {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translateX(15px);
}

.move-up {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translateY(-15px);
}

.move-down {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translateY(15px);
}

.move-up-right {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translate(10px, -10px);
}

.move-down-right {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translate(10px, 10px);
}


.move-up-left {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translate(-10px, -10px);
}

.move-down-left {
  transition: 2s ease;
  background: var(--green) !important;
  color: black !important;
  transform: translate(-10px, 10px);
}

.pressed { 
  background: var(--green) !important;
  color: black !important;
}