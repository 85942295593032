html {
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: avenir, nunito;
  background: var(--black-purple);
  overscroll-behavior: none;
  position: relative;
}

:-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

::-webkit-scrollbar-button {
  display: none;
}

code {
  font-family: avenir, nunito, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

:root {
  --shadow-1: 0 10px 6px -6px rgba(0, 0, 0, 0.5);
  --green-shadow: 0 10px 6px -6px rgba(105, 240, 174, 0.5);
  --blue-shadow: 0 10px 6px -6px rgba(33, 148, 182, 0.5);
  --wide-blue-shadow: 0 10px 10px -4px rgba(33, 148, 182, 0.5);
  --purple-shadow: 0 10px 6px -6px rgba(103, 58, 183, 0.5);
  --purple: #673ab7;
  --dark-purple: #6200ea;
  --black-purple: #070a29;
  --green: #69f0ae; /* 105, 240, 174 */
  --blue: #2194b6; /* 33, 148, 182 */
  --pink: #e91e63;

  --purple-gradient: linear-gradient(90deg, var(--purple), var(--dark-purple), var(--dark-purple));
  --green-purple-gradient: linear-gradient(135deg, #00bcd4, var(--dark-purple));
  --green-red-gradient: linear-gradient(135deg, #00bcd4, lightcoral);
  --background-gradient: linear-gradient(-45deg, var(--black-purple), #3d0091);



  /* Light Theme */
  /* --black-purple: #ffffff; */

}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  filter: invert(1);
  font-size: 16px;
  font-family: avenir, nunito;
  font-weight: bold;
  /* color: #291707; */
  transition: background-color 5000s ease-in-out 0s;
}

video:focus {
  outline-color: var(--purple);
}

.toast {
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 0.5em 1em;
  border-radius: 50px 50px 50px 0;
  background-color: var(--green);
  animation: animate 4s ease-in-out forwards;
  font-size: 18px;
  z-index: 1000;
  box-shadow: var(--shadow-1);
}

.rc-slider-tooltip {
  z-index: 1000 !important;
}

.rc-slider-tooltip-inner {
  background-color: var(--green) !important;
  font-weight: bold;
  color: black !important;
  box-shadow: var(--green-shadow) !important;
}

.rc-slider-tooltip-arrow {
  border-top-color: var(--green) !important;
}

@keyframes animate {
  0% {
    bottom: 0px;
    opacity: 0;
  }

  20% {
    bottom: 50px;
    opacity: 1;
  }
  80% {
    bottom: 50px;
    opacity: 1;
  }
  100% {
    bottom: 50px;
    opacity: 0;
    z-index: -1;
    display: none;
  }
}

a {
  text-decoration: none;
}

::selection {
  background-color: var(--green);
  color: white;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(70px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
